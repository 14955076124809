<template>
	<div class="main-wrap">
		<div class="content-list">
			<div class="item">
			 <div class="info-wrap">
					<div class="info-row">
						<span class="label">单号:</span>
						<span class="value">702357024</span>
					</div>
					<div class="info-row">
						<span class="label">患者:</span>
						<span class="value">王一一</span>
					</div>
					<div class="info-row">
						<span class="label">床号:</span>
						<span class="value">23</span>
					</div>
					<div class="info-row">
						<span class="label">房间:</span>
						<span class="value">33</span>
					</div>
					<div class="info-row">
						<span class="label">工勤:</span>
						<span class="value">刘美美</span>
					</div>
					<div class="info-row">
						<span class="label">位置:</span>
						<span class="value">门诊一楼大门</span>
					</div>
					<div class="info-row">
						<span class="label">目的地:</span>
						<span class="value">门诊1号手术室</span>
					</div>
				</div>
				
				<div class="handle-row">
					<p class="task">进度:30%</p>
					<button type="button">轨迹</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'taskQuery',
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
.main-wrap {
	.content-list{
		padding: 10px;
		.item{
			background: linear-gradient(180deg, #D1DEFF 0%, #FFFFFF 100%);
			box-shadow: 0px 1px 1px 0px rgba(100,101,102,0.1);
			border-radius: 8px;
			padding: 8px 12px;
			&:not(:first-child){
				margin-top: 10px;
			}
			.info-wrap{
				display: flex;
				flex-wrap: wrap;
				.info-row{
					width: 50%;
					font-size: 14px;
					display: flex;
					align-items: center;
          $labelWidth:50px;
					padding: 2px 0;
					.label{
						color: #666;
						width: #{$labelWidth};
					}
					.value{
						color: #333;
						width: calc(100% - #{$labelWidth});
					}
				}
			}
			.handle-row{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 16px;
				position: relative;
				&::after{
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: -8px;
					border-top: 1px solid #ddd;
				}
				.task{
					color: #3e73fb;
					font-size: 14px;
					font-weight: 700;
				}
				button{
					padding: 0px 16px;
					line-height: 24px;
					border-radius: 24px;
					border: 1px solid #3e73fb;
					color: #3e73fb;
					font-size: 14px;
					background: none;
				}
			}
		}
	}
}
</style>
